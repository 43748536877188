import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout/layout"
import * as T from "../components/Typography"
import * as Templates from "../components/Timeline/templates"

const DATA = {
  frontmatter: {
    title: "title",
    fullDate: "string",
    shortDate: "shortDate",
    template: "string",
    illustration: "string",
    illustrationOrder: "back",
    image: undefined,
    video: undefined,
  },
  html: "Commodo sunt reprehenderit occaecat excepteur magna aliqua do qui.",
}

const DATA_LINK = {
  ...DATA,
  html: "<p>Commodo sunt reprehenderit occaecat excepteur magna aliqua do qui.</p> <p>link cheat sheet: [label](linkurl)</p> <a href=``>Link</a>",
}

const DATA_VIDEO = {
  ...DATA,
  frontmatter: {
    ...DATA.frontmatter,
    video: "3dq7aDMdS7g",
  },
}

const DATA_IMAGE = {
  ...DATA,
  frontmatter: {
    ...DATA.frontmatter,
    image: {
      childImageSharp: {
        fluid: {
          aspectRatio: 1.6505376344086022,
          sizes: "(max-width: 400px) 100vw, 400px",
          src: "https://picsum.photos/200/300",
        },
      },
    },
  },
}

const TemplatesPage = () => {
  const { allFile } = useStaticQuery(query)

  return (
    <Layout>
      <div style={{ paddingTop: "10vh" }}>
        <T.Title>Templates:</T.Title>

        <br />

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div>
            {/* Text */}
            <h2>
              template id: <strong>text</strong>
            </h2>

            <Templates.Text data={DATA} ref={() => {}} />
          </div>

          <div>
            <h2>
              template id: <strong>text</strong> (with link)
            </h2>

            <Templates.Text data={DATA_LINK} ref={() => {}} />
          </div>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {/* Card */}
          <div>
            <h2>
              template id: <strong>card</strong>
            </h2>

            <Templates.Card data={DATA} ref={() => {}} />
          </div>

          <div>
            <h2>
              template id: <strong>card</strong> (with link)
            </h2>

            <Templates.Card data={DATA_LINK} ref={() => {}} />
          </div>

          <div>
            <h2>
              template id: <strong>card</strong> (with image)
            </h2>

            <Templates.Card data={DATA_IMAGE} ref={() => {}} />
          </div>

          <div>
            <h2>
              template id: <strong>card</strong> (with video)
            </h2>

            <Templates.Card data={DATA_VIDEO} ref={() => {}} />
          </div>
        </div>

        {/* Mosaic */}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div>
            <h2>
              template id: <strong>mosaic</strong>
            </h2>

            <Templates.Mosaic data={DATA} ref={() => {}} />
          </div>

          <div>
            <h2>
              template id: <strong>mosaic</strong> (with link)
            </h2>

            <Templates.Mosaic data={DATA_LINK} ref={() => {}} />
          </div>

          <div>
            <h2>
              template id: <strong>mosaic</strong> (with image)
            </h2>

            <Templates.Mosaic data={DATA_IMAGE} ref={() => {}} />
          </div>
        </div>

        <T.Title>Images:</T.Title>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {allFile.edges.map((e: any) => (
            <div
              key={e.node.name}
              style={{ border: "1px solid #eee", margin: "1em" }}
            >
              <h2>
                image id: <strong>{e.node.name}</strong>
              </h2>
              <Img
                style={{ width: "300px" }}
                fluid={e.node.childImageSharp.fluid}
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default TemplatesPage

const query = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "illustrations" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
